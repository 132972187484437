// Function to handle WebRTC connection
var connection = new RTCMultiConnection();

function manyToMany(roomId, functionName) {
  
  let count = 0;
  // Setting the socket server URL for connection

  connection.socketURL = "https://awrxhub.com:9550/";
  const role= localStorage.getItem('UserRole').toLocaleLowerCase()
  const info= JSON.parse(localStorage.getItem('userInfo'))
  connection.socketMessageEvent = "video-conference-demo";
  connection.extra = {
    role: localStorage.getItem('UserRole'),
    userName: info.FullName,
    UserId: info.UserId,
    info:localStorage.getItem('userInfo')
};
  connection.session = {
    audio: role === 'teacher',
    video: true,
    data: true  
  };
  if (document.getElementById("screenShare")){
    var btnScreenShare = document.getElementById("screenShare");
    btnScreenShare.onclick = function () {
    }        
  }
  if (document.getElementById("stopscreenShare")){
    var btnStopscreenShare = document.getElementById("stopscreenShare");
    btnStopscreenShare.onclick = function () {
      connection.session = {
        audio: role === 'teacher',
        video: true,
        data: true  
      };
    }
  }
  // Constraints for SDP (Session Description Protocol)
 connection.sdpConstraints.mandatory = {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true,
  };
  // Detecting available input devices (microphone, webcam, speakers)
  connection.DetectRTC.load(function () {
    if (connection.DetectRTC.hasMicrophone === true) {
      connection.mediaConstraints.audio = true;
      connection.session.audio = true;
    } else {
      alert("Please attach a microphone device.");
      connection.mediaConstraints.audio = false;
      connection.session.audio = false;
    }

    if (connection.DetectRTC.hasWebcam === true) {
      connection.mediaConstraints.video = true;
      connection.session.video = true;
    } else {
      alert("Please attach a camera device.");
      connection.mediaConstraints.video = false;
      connection.session.video = false;
    }
    if (connection.DetectRTC.hasSpeakers === false) {
      // checking for "false"
      alert(
        "Please attach a speaker device. You will unable to hear the incoming audios."
      );
    }
  });
  var bitrates = 512;
  var resolutions = "Ultra-HD";
  var videoConstraints = {};
  if (resolutions == "HD") {
    videoConstraints = {
      width: {
        ideal: 1280,
      },
      height: {
        ideal: 720,
      },
      frameRate: 30,
    };
  }
  if (resolutions == "Ultra-HD") {
    videoConstraints = {
      width: {
        ideal: 1920,
      },
      height: {
        ideal: 1080,
      },
      frameRate: 30,
    };
  }
  connection.mediaConstraints = {
    video: videoConstraints,
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
    },
  };
  // Handling SDP to set video codecs and bitrates

  var CodecsHandler = connection.CodecsHandler;

  connection.processSdp = function (sdp) {
    var codecs = "vp8";

    if (codecs.length) {
      sdp = CodecsHandler.preferCodec(sdp, codecs.toLowerCase());
    }

    if (resolutions == "HD") {
      sdp = CodecsHandler.setApplicationSpecificBandwidth(sdp, {
        audio: 128,
        video: bitrates,
        screen: bitrates,
      });

      sdp = CodecsHandler.setVideoBitrates(sdp, {
        min: bitrates * 8 * 1024,
        max: bitrates * 8 * 1024,
      });
    }

    if (resolutions == "Ultra-HD") {
      sdp = CodecsHandler.setApplicationSpecificBandwidth(sdp, {
        audio: 128,
        video: bitrates,
        screen: bitrates,
      });

      sdp = CodecsHandler.setVideoBitrates(sdp, {
        min: bitrates * 8 * 1024,
        max: bitrates * 8 * 1024,
      });
    }

    return sdp;
  };

  // Setting ICE servers for STUN/TURN

  connection.iceServers = [
    {
      urls: [
        "stun:onmedrx.org:5349",
        "stun:stun1.l.google.com:19302",
        "stun:stun2.l.google.com:19302",
        "stun:stun.l.onmedmedia.com:5349",
        "stun:stun1.l.onmedmedia.com:5349",
      ],
    },
  ];

  connection.iceServers.push({
    urls: "turn:onmedrx.org:5349",
    credential: "test123",
    username: "test",
  });
  // Setting the containers for local and remote videos

  connection.localVideosContainer = document.getElementById("local");
  connection.remoteVideosContainer = document.getElementById("remote");
  // Handling the onstream event to display video streams

  connection.onstream = function (event) {
    var existing = document.getElementById(event.streamid);
    var userExtraRole = event.extra.role.toLocaleLowerCase();
    var userExtraName = event.extra.userName.toLocaleLowerCase();
    var isScreen =event.extra.screenShare??false
    if (existing && existing.parentNode) {
      existing.parentNode.removeChild(existing);
    }
    event.mediaElement.muted = true;
    event.mediaElement.volume = 0;
    var video = document.createElement("video");
    try {
      video.setAttributeNode(document.createAttribute("autoplay"));
      video.setAttributeNode(document.createAttribute("playsinline"));
      video.setAttributeNode(document.createAttribute("id", "videofeed"));
    } catch (e) {
      video.setAttribute("autoplay", true);
      video.setAttribute("playsinline", true);
    }
    video.srcObject = event.stream;
    let displayButtons = document.getElementById("displayButtons");
    displayButtons.style.display = "flex";
    if (event.type === "local") {
      video.volume = false;
      video.id = event.userid;
      try {
        video.setAttributeNode(document.createAttribute("muted"));
      } catch (e) {
        video.setAttribute("muted", true);
      }
      var width = 500;
      var mediaElement = getHTMLMediaElement(video, {
        title: userExtraName,
        buttons: ["full-screen"],
        width: width,
        showOnMouseEnter: true,
      });
      // mediaElement.addClass(userExtraInfo.role.toLowerCase() === "student"? "student":"teacher")
      connection.localVideosContainer.appendChild(mediaElement);
      var localVideosContainer = document.getElementById("local");
      localVideosContainer.appendChild(mediaElement);
      if (document.getElementById("localloaderbutton")) {
        $("#localloaderbutton").hide();
      }
      if (document.getElementById("btnLeave")) {
        var btnLeave = document.getElementById("btnLeave");
        btnLeave.onclick = function () {
          connection.getAllParticipants().forEach(function (pid) {
            connection.disconnectWith(pid);
          });
          connection.attachStreams.forEach(function (localStream) {
            localStream.stop();
          });
          connection.closeSocket();
        };
      }
      if (document.getElementById("fullWidth")) {
        const fullWidth = document.getElementById("fullWidth");
        fullWidth.onclick = function () {
          $("#showconsultationsidebar").addClass("fullVideoWidth");
          $("#fullWidth").hide();
          $("#bigscreen").hide();
          $("#closefullWidth").show();
        };
      }
      if (document.getElementById("closefullWidth")) {
        const closefullWidth = document.getElementById("closefullWidth");
        closefullWidth.onclick = function () {
          $("#showconsultationsidebar").removeClass("fullVideoWidth");
          $("#closefullWidth").hide();
          $("#fullWidth").show();
          $("#bigscreen").show();
        };
      }
      if (document.getElementById("bigscreen")) {
        const bigscreen = document.getElementById("bigscreen");
        bigscreen.onclick = function () {
          $("#showconsultationsidebar").addClass("small_screen_local");
          $("#sectiondata").addClass("small_screen");
          $("#sectiondata").removeClass("big_screen");
          $("#showconsultationsidebar").removeClass("big_screen_local");
          $("#bigscreen").hide();
          $("#fullWidth").hide();
          $("#smallscreen").show();
        };
        const smallscreen = document.getElementById("smallscreen");
        smallscreen.onclick = function () {
          $("#showconsultationsidebar").addClass("big_screen_local");
          $("#sectiondata").addClass("big_screen");
          $("#sectiondata").removeClass("small_screen");
          $("#showconsultationsidebar").removeClass("small_screen_local");
          $("#smallscreen").hide();
          $("#bigscreen").show();
          $("#fullWidth").show();
        };
      }
      var btnMute = document.getElementById("btnMute");
      btnMute.onclick = function () {
        $("#btnMute").hide();
        $("#btnUnMute").show();
        connection.attachStreams.forEach(function (localStream) {
          localStream.mute("audio");
        });
      };
      var btnUnMute = document.getElementById("btnUnMute");
      btnUnMute.onclick = function () {
        $("#btnMute").show();
        $("#btnUnMute").hide();
        connection.attachStreams.forEach(function (localStream) {
          localStream.unmute("audio");
        });
        connection.mediaConstraints = {
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
          },
        };
      };
      var btnCam = document.getElementById("off-cam");
      btnCam.onclick = function () {
        $("#off-cam").hide();
        $("#start-cam").show();
        connection.attachStreams.forEach(function (localStream) {
          localStream.mute("video");
        });
      };
      var btnStartCam = document.getElementById("start-cam");
      btnStartCam.onclick = function () {
        $("#off-cam").show();
        $("#start-cam").hide();

        connection.attachStreams.forEach(function (localStream) {
          localStream.unmute("video");
        });
      };
    } else if (event.type === "remote") {
      video.id = event.userid;
      connection.onleave = function (event) {
        count--;
        if (count < 2) {
          // $("#showconsultationsidebar").removeClass("student_max04");
          $("#showconsultationsidebar").removeClass("student_max04");
          // $("#patientVideo").addClass("multipleFeed");
        } else if (count < 5) {
          console.log(count);
          $("#showconsultationsidebar").removeClass("student_max05");
          // $("#patientVideo").addClass("multipleFeed");
        }
      };


      if (document.getElementById("btnLeave")) {
        var btnLeave = document.getElementById("btnLeave");
        btnLeave.onclick = function () {
          connection.getAllParticipants().forEach(function (pid) {
            connection.disconnectWith(pid);
          });
        };
      }
      if(connection.getAllParticipants().length >3){
        $("#showconsultationsidebar").removeClass("singleVideo")
        $("#showconsultationsidebar").removeClass("twovideo")
      }
      connection.getAllParticipants().forEach(function (participantId) {
        var user = connection.peers[participantId];
        var hisIncomingStreams = user.peer.getReceivers();
        if (document.getElementById("remoteloaderbutton")) {
          $("#remoteloaderbutton").hide();
        }
      });
        var width = 500;
        var mediaElement = getHTMLMediaElement(video, {
        title: userExtraName,
        buttons: ["full-screen"],
        width: width,
        showOnMouseEnter: true,
        });
        // mediaElement.addClass(userExtraInfo.role.toLowerCase() === "teacher"? "teacher":'')
        connection.remoteVideosContainer.appendChild(mediaElement);

        var remoteVideosContainer = document.getElementById("remote");
        remoteVideosContainer.appendChild(mediaElement);
        count++;
        if (count > 4) {
          $("#showconsultationsidebar").addClass("student_max04");
          $("#showconsultationsidebar").addClass("student_max05");
          // $("#patientVideo").addClass("multipleFeed");
        } else if (count >= 2) {
          $("#showconsultationsidebar").addClass("student_max04");
          // $("#patientVideo").addClass("multipleFeed");
        }
    }

    setTimeout(function () {
      mediaElement.media.play();
    }, 5000);

    mediaElement.id = event.streamid;

    // to keep room-id in cache
    localStorage.setItem(connection.socketMessageEvent, connection.sessionid);

    if (event.type === "local") {
      connection.socket.on("disconnect", function () {
        if (!connection.getAllParticipants().length) {
        }
      });
    }
  };

  // Handling the onstreamended event

  connection.onstreamended = function (event) {
    if (mediaElement) {
      mediaElement.parentNode.removeChild(mediaElement);
    }
  };

  // Handling media errors

  connection.onMediaError = function (e) {
    if (e.message === "Concurrent mic process limit.") {
      if (DetectRTC.audioInputDevices.length <= 1) {
        alert("Please select external microphone.");
        return;
      }

      var secondaryMic = DetectRTC.audioInputDevices[1].deviceId;
      connection.mediaConstraints.audio = {
        deviceId: secondaryMic,
      };

      // connection.join(connection.sessionid);
    }
  };

  function disableInputButtons(enable) {}

  
  (function () {
    var params = {},
      r = /([^&=]+)=?([^&]*)/g;

    function d(s) {
      return decodeURIComponent(s.replace(/\+/g, " "));
    }
    var match,
      search = window.location.search;
    while ((match = r.exec(search.substring(1))))
      params[d(match[1])] = d(match[2]);
    window.params = params;
  })();

  var roomid = "";
  if (localStorage.getItem(connection.socketMessageEvent)) {
    roomid = localStorage.getItem(connection.socketMessageEvent);
  } else {
    roomid = connection.token();
  }

  var hashString = location.hash.replace("#", "");
  if (hashString.length && hashString.indexOf("comment-") == 0) {
    hashString = "";
  }

  var roomid = params.roomid;
  if (!roomid && hashString.length) {
    roomid = hashString;
  }

  if (roomId && roomId.length) {
    localStorage.setItem(connection.socketMessageEvent, roomId);

    connection.checkPresence(roomId, function (isRoomExist, roomId) {
      if (functionName == "open") {
        if (isRoomExist === true) {
          arrStr = [];
        } else {
          connection.open(roomId);
        }
      }

      if (functionName == "join") {
        if (isRoomExist === true) {
          connection.join(roomId);
        } else {
          setTimeout(function () {
            webrtc(roomId, "join");
          }, 5000);
        }
      }

      if (functionName == "openOrJoin") {
        connection.openOrJoin(roomId);
      }
    });

    disableInputButtons();
  }
  connection.onmessage = function(event) {
    const senderRole=event.extra.role
    const senderUser=event.extra.userName
    var data = JSON.parse(event.data)
    if(data.type=="message")
      appendElements(data.text,senderUser,"incoming")
    else if(data.type=="handraise"){
      handRaise(event)
    }else if(data.type=="handraise-accepted"){
      // Get the button element by its ID
      const button = document.getElementById('showchat');
      if(connection.userid != data.receiver){
        document.getElementById('input-container').remove();
      }
      // Check if the button exists before trying to click it
      if (button) {
          button.click();
      }
    }else if(data.type=="creatingGroup"){
      creatingGroup(event)
    }else if(data.type=="splitGroupList"){
      splitGroupList(event)
    }
  
  };
  if (
    navigator.connection &&
    navigator.connection.type === "cellular" &&
    navigator.connection.downlinkMax <= 0.115
  ) {
    alert("2G is not supported. Please use a better internet service.");
  }
}
function cameraOff() {
  const video = document.querySelector("video");

  // A video's MediaStream object is available through its srcObject attribute
  if (video) {
    const mediaStream = video.srcObject;

    // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
    const tracks = mediaStream.getTracks();

    // Tracks are returned as an array, so if you know you only have one, you can stop it with:
    tracks[0].stop();

    // Or stop all like so:
    tracks.forEach((track) => track.stop());
  }
}

function getActiveParticipants(){
  var list=[]
  connection.getAllParticipants().forEach(function (participantId) {
    let user = connection.peers[participantId];
    list.push(user.extra.UserId)
  });
  return list
}

function sendMessage(text) {
  connection.send(text); // Send the text message
  var data = JSON.parse(text)
  const info= JSON.parse(localStorage.getItem('userInfo'))
  const userName= info.FullName
  if(data.type=="message")
    appendElements(data.text,userName,"outgoing")
}
function appendElements(msg,userName,classList) {
  // Get the parent div element
  const parentDiv = document.getElementById('message_box');

  // Create a new div element
  const newDiv = document.createElement('div');
  const msgs = document.createElement('p');
  msgs.classList.add("msg")
  msgs.textContent = `${msg}`; // Add content to the new div
  const sender = document.createElement('span');
  sender.classList.add("userName")
  sender.textContent = `${userName}`; // Add content to the new div
  
  newDiv.classList.add('message-container'); // Add content to the new div
  newDiv.classList.add(classList); // Add content to the new div
  newDiv.appendChild(msgs)
  newDiv.appendChild(sender)

  // Create a new <br> element
  const br = document.createElement('br');

  // Append the new <br> and new div to the parent div
  // parentDiv.appendChild(br);
  parentDiv.appendChild(newDiv);
}