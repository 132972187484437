function screenRecording() {
  const captureBtn = document.getElementById("captureBtn");
  const stopBtn = document.getElementById("stopBtn");
  const download = document.getElementById("download");
  const previewBtn = document.getElementById("previewBtn");
  // Check if the browser supports screen capture
    
  if ("getDisplayMedia" in navigator.mediaDevices);

  let blobs; // Array to store recorded data blobs
  let blob; // Combined blob of recorded data
  let rec; // MediaRecorder instance for recording
  let stream; // Combined stream of video and audio
  let voiceStream; // Stream for microphone audio
  let desktopStream; // Stream for desktop video

  const mergeAudioStreams = (desktopStream, voiceStream) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    // Connect desktop audio stream if available

    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    // Connect microphone audio stream if available

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }
    // Return audio tracks if either desktop or voice stream is available

    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
  };

  // Event handler for capture button click
  captureBtn.onclick = async () => {
    download.style.display = "none";
    captureBtn.style.display = "none";
    previewBtn.style.display = "none";
    stopBtn.style.display = "flex";
    // Determine if audio and microphone should be captured
    const audio = true || false; // Set to true to capture audio
    const mic = true || false; // Set to true to capture microphone

    // Start capturing desktop video
    desktopStream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: audio,
    });
    
    // Start capturing microphone audio if enabled
    if (mic === true) {
      voiceStream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: mic,
      });
    }
    // console.log(navigator.mediaDevices)
    // Combine desktop video and microphone audio streams
    const tracks = [
      ...desktopStream.getVideoTracks(),
      ...mergeAudioStreams(desktopStream, voiceStream),
    ];

    // Create combined media stream
    stream = new MediaStream(tracks);

    // Initialize MediaRecorder with combined stream
    blobs = [];

    rec = new MediaRecorder(stream, {
      mimeType: "video/webm; codecs=vp8,opus",
    });

    // Event listener for data availability during recording

    rec.ondataavailable = (e) => blobs.push(e.data);
    // Event listener for recording stop
    rec.onstop = async () => {
      // Combine recorded data blobs into a single Blob
      blob = new Blob(blobs, { type: "video/webm" });

      // Create URL for downloading the recorded video

      let url = window.URL.createObjectURL(blob);
      localStorage.setItem("videoUrl",url)
      download.href = url;
      download.download = "test.webm";
      download.style.display = 'flex';
      previewBtn.style.display = 'flex';
      let roomId = localStorage.getItem('room_id');
      
      
      
      const baseUrl = localStorage.getItem("apiUrl")+'/uploadRecordedFiles';
      // Define URL parameters
      const params = new URLSearchParams({
        roomId
      }).toString();

      // Combine the base URL with the parameters
      const urlWithParams = `${baseUrl}?${params}`;

      
      
      var formData = new FormData();
      
      // formData.append("avail_id", AvailId);
      formData.append("recording_file", blob, "recording.webm"); // Assuming 'recording' is the field name

    
      let schoolauth = localStorage.getItem("schoolAuth");
      fetch(urlWithParams, {
        method: 'POST',
        headers: {
          schoolauth,
          // 'Content-Type': 'application/json',  // Set the content type to JSON
      },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            localStorage.setItem("recordingdata", true);
            console.log("Recording uploaded successfully");
          } else {
            localStorage.setItem("recordingerror", true);
            console.error("Failed to upload recording:", response.statusText);
          }
        })
        .catch((error) => {
          console.error("Error uploading recording:", error);
        });
    };

    // Start recording after a delay (to allow setup time)
    setTimeout(() => {
      console.log("llllllllllll");
      rec.start();
    }, 1000);
  };

  stopBtn.onclick = () => {
    stopBtn.style.display = "none";
    captureBtn.style.display = "flex";
    download.style.display = "flex";

    rec.stop();
    stream.getTracks().forEach((s) => s.stop());
  };
}
